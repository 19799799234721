import Drawer from "@mui/material/Drawer";
import { TbLogin2 } from "react-icons/tb";
import { Link } from "react-router-dom";
import AccordionHam from "./AccodionHam";
import { useState } from "react";
import AccodionMyAccount from "./AccodionMyAccount";
export default function Ham({
  isOpen,
  setIsOpen,
  loginStatus,
  LogOut,
  onLoginClick,
}) {
  const [filepath, setFilepath] = useState(
    localStorage.getItem("profileURL") || ""
  );

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const handleLogin = () => {
    setIsOpen(false);
    onLoginClick();
  };

  return (
    <div className="xl:hidden">
      <Drawer anchor={"left"} open={isOpen} onClose={toggleDrawer(false)}>
        <div className="w-[300px]  bg-white p-4 font-circular flex flex-col">
          <div className="flex-grow">
            <div className="w-full flex justify-center items-center mt-2">
              <img
                src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/logo.webp"
                alt="logo"
                className="max-w-[130px] w-full"
              />
            </div>
            {loginStatus && <AccodionMyAccount />}
            <AccordionHam />
            {/* Render Login and Try for free sections below AccordionHam if not logged in */}
            {!loginStatus && (
              <div className="mt-auto ">
                <div
                  onClick={handleLogin}
                  className="w-full flex items-center gap-2 text-lg text-universal px-4 py-1 bg-blue-200/15 rounded-sm cursor-pointer mb-2"
                >
                  <div className="flex justify-center items-center gap-2 w-full text-center">
                    <TbLogin2 />
                    <h1 className="font-semibold text-center">Login</h1>
                  </div>
                </div>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://trial.accredian.com"
                  className="mt-2"
                >
                  <div className="w-full flex items-center gap-2 text-lg text-universal px-4 py-1 bg-blue-200/15 rounded-sm cursor-pointer">
                    <h1 className="font-semibold w-full text-center">
                      Try for free
                    </h1>
                  </div>
                </a>
              </div>
            )}
          </div>
          <div>
            <a href={`${process.env.REACT_APP_BASE_URL}/referandearn`} target="_blank" rel="noreferrer">
              <img src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/dropDownBanner.webp" className="" alt="" />
            </a>
          </div>
          {loginStatus && (
            <>
              <button
                className="py-2 px-4 w-full hover:text-red-500 bg-red-500 text-white hover:bg-red-400/30 rounded-md cursor-pointer"
                onClick={() => LogOut("token", "accredian.com")}
              >
                <h2 className="font-semibold capitalize">Logout</h2>
              </button>
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
}
